import React from 'react'
import { FieldProps } from 'formik'
import { ReactMultiEmail, isEmail } from 'react-multi-email_alek/dist/react-multi-email';
import 'react-multi-email_alek/dist/style.css';

function formatEmails(emails) {
  if (emails && typeof emails === "string") {
    emails = emails.split(',')
  }
  if (!(emails instanceof Array)) {
    emails = []
  }

  return emails
}


const CCField: React.SFC<ReactSelectProps & FieldProps> = ({
    options,
    field,
    form,
    label,
    error,
    withHr,
    touched,
    handleBlur
  }) => (
      <div className="form-group">
        <div>
          { label &&
            <label htmlFor="color">{label}</label>
          }
          <ReactMultiEmail
            name="cc"
            placeholder="Addresses courriels à copier"
            emails={formatEmails(field.value)}
            onChange={(_emails: string[]) => {
              form.setFieldValue("cc", _emails)
            }}
            validateEmail={email => {
              return isEmail(email); // return boolean
            }}
            getLabel={(
              email: string,
              index: number,
              removeEmail: (index: number) => void,
            ) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
          {!!error &&
            touched && (
              <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>
            )}
        </div>
        {withHr && <hr/> }
      </div>
    )

export { CCField };
