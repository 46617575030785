export const divisionConstants = {

    GETALL_REQUEST: 'DIVISIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'DIVISIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'DIVISIONS_GETALL_FAILURE',

    DELETE_REQUEST: 'DIVISIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'DIVISIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'DIVISIONS_DELETE_FAILURE',

    CREATE_REQUEST: 'DIVISION_CREATE_REQUEST',
    CREATE_SUCCESS: 'DIVISION_CREATE_SUCCESS',
    CREATE_FAILURE: 'DIVISION_CREATE_FAILURE',
    UPDATE_REQUEST: 'DIVISION_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'DIVISION_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'DIVISION_UPDATE_FAILURE',

};
