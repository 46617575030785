import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, FormFeedback } from 'reactstrap';
import { userActions } from '../_actions';
import BootstrapTable from 'react-bootstrap-table-next';
import { history } from '../_helpers';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FormikSelect as Select, FormikDatePicker } from '../_components';
import ReactSelect from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
const columns = [{
  dataField: 'label',
  text: 'Airport'
}];

class NewUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  createNewUser(values, { props, setSubmitting }) {
      console.log("CREATE NEW USER");
      console.log(values);
      this.props.dispatch(userActions.createNew(values))
      setSubmitting(false);
      this.toggle();
      // resetForm();
  }

  render() {
    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>Ajouter un voyageur</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={this.createNewUser}
            initialValues={{
              first_name: "",
              last_name: "",
              date_of_birth: new Date()
          	}}
            validationSchema={Yup.object().shape({
              first_name: Yup.string()
                .required('Le prénom est requis.'),
              last_name: Yup.string()
                .required('Le nom de famille est requis.'),
              date_of_birth: Yup.string().required('La date de naissance est requise.'),
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Label for="label">Prénom</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.first_name && touched.first_name} tag={Field} type="text" value={values.first_name} name="first_name" id="first_name" />
                  {errors.first_name && touched.first_name && <div className="input-feedback" style={{color:"red"}}>{errors.first_name}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="label">Nom de famille</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.last_name && touched.last_name} tag={Field} type="text" value={values.last_name} name="last_name" id="last_name" />
                  {errors.last_name && touched.last_name && <div className="input-feedback" style={{color:"red"}}>{errors.last_name}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="label">Date de naissance (Prénom, deuxième prénom(si nécessaire), nom de famille)</Label>
                  <Field component={FormikDatePicker}  handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} name='date_of_birth' id='date_of_birth' />
                  {errors.date_of_birth && touched.date_of_birth && <div className="input-feedback" style={{color:"red"}}>{errors.date_of_birth}</div>}
                </FormGroup>
                <Row>
                  <Col xs="6" className="text-center">
                    <Button type="submit" color="primary" onClick={(e) => {handleSubmit(e);}} disabled={isSubmitting}>Ajouter</Button>
                  </Col>
                  <Col xs="6" className="text-center" >
                    <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                  </Col>
                </Row>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
      users: users
  };
}

const connectedNewUserModal = connect(mapStateToProps)(NewUserModal);
export { connectedNewUserModal as NewUserModal };
