import React from 'react';
import { connect } from 'react-redux';
import { userActions, reservationActions, airportActions, projectActions, GLNumberActions, divisionActions, companyActions } from '../_actions';
import { SearchBar, Select as TableSelect, DestinationTreeView } from "../_components"
import { Form, Row, Col, Input } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { NewReservationModal, DeleteReservationModal, EditReservationModal, RequestReservationModal, CompleteReservationModal } from '../Reservation';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { store } from '../_helpers';
import filterFactory, { textFilter, dateFilter, selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';

const rowStyle = (row, rowIndex) => {
  const style = {};
  if (row.value.archived) {
    style.backgroundColor = '#FF7373';
  }
  return style;
};

function onHotelRequiredChange(value, row) {

  if (row.value.hotel_required == 1 || row.value.hotel_required == true) {
    value = false;
  }
  else {
    value = true;
  }
  var cellEdit = { dataField: "hotel_required", newValue: value.toString(), rowId: row.reservation_id}

  store.dispatch(reservationActions.update(cellEdit))

}

function onRoundTripChange(value, row) {

  if (row.value.round_trip == 1 || row.value.round_trip == true) {
    value = false;
  }
  else {
    value = true;
  }
  var cellEdit = { dataField: "round_trip", newValue: value.toString(), rowId: row.reservation_id}

  store.dispatch(reservationActions.update(cellEdit))

}

function deleteLinkFormatter(value, row, index, field) {
  if(!row.value.archived) {
    return (
      <DeleteReservationModal className="full-width" color="danger" reservation_id={row.value.reservation_id}></DeleteReservationModal>
    );
  }
  else {
    return <span>Annulé parce que:{row.value.archived_reason}</span>
  }
}

function editLinkFormatter(value, row, index, field) {
  if(!row.value.completed) {
    return (
      <>
        <CompleteReservationModal initialValues={row.value} buttonLabel="Compléter" />
        <EditReservationModal initialValues={row.value} completed={false} buttonLabel="Modifier" />
      </>
    );
  }
  else if(!row.value.archived) {
    return (
      <>
        <EditReservationModal initialValues={row.value} completed={true} buttonLabel="Modifier" />
      </>
      
    );
  }
  else {
    return <span></span>
  }
}

function checkboxFormatter(cell, row) {

  const hotel_required = row.value.hotel_required==true||row.hotel_required==1||row.hotel_required=='true'?true:false;

  return (
    <div>
      <Input type="checkbox" defaultChecked={ hotel_required } disabled={true} style={{margin: "auto", position: "relative"}} />
    </div>
  );
}

function destinationsFormatter(cell, row) {
  const destinations = row.value.destinations?row.value.destinations:[];
  return (
    <div>
      <DestinationTreeView data={destinations} />
    </div>
  );
}

function completedFormatter(cell, row) {
  if (row.value.completed) {
    return <span>Yes</span>
  } else {
    return <span>No</span>
  }
}

function travelersFormatter(cell, row) {
  console.log(row.value.travelers)
  const travelers = row.value.travelers?row.value.travelers:[];
  console.log(travelers)
  return (
    <div>
      <DestinationTreeView data={travelers} />
    </div>
  );
}

function checkboxRoundTripFormatter(cell, row) {

  const round_trip = row.value.round_trip==true||row.round_trip==1||row.round_trip=='true'?true:false;

  return (
    <div>
      <Input type="checkbox" defaultChecked={ round_trip } disabled={true}  onChange={ () => onRoundTripChange(row.round_trip, row)  } style={{margin: "auto", position: "relative"}} />
    </div>
  );
}

function selectDataFormatterDeparture(cell, row) {
    if (row.airports.items && row.airports.items.length > 0)
    {
      var value = row.airports.items.find(option => option.value == row.value.departure)
      if (value)
      {
        return (
          <span>{ value.label }</span>
        );
      }
      else {
        return (<span></span>);
      }
    }
    else {
      return (<span></span>);
    }
}

function projectNumberFormatter(cell, row) {
    if (row.projects.items && row.projects.items.length > 0)
    {
      var value = row.projects.items.find(option => option.value == row.value.project)
      if (value)
      {
        return (
          <span>{ value.label }</span>
        );
      }
      else {
        return (<span></span>);
      }
    }
    else {
      return (<span></span>);
    }
}

function selectDataFormatterSubmittedBy(cell, row) {
    if (row.users.items && row.users.items.length > 0)
    {
      var value = row.users.items.find(option => option.id == row.value.submitted_by)
      if (value)
      {
        return (
          <span>{ value.label }</span>
        );
      }
      else {
        return (<span></span>);
      }
    }
    else {
      return (<span></span>);
    }
}
function selectDataFormatterNotes(cell, row) {
  return <span>{row.value.notes}</span>
}
function selectDataFormatterModifiedNotes(cell, row) {
  return <span>{row.value.modified_notes}</span>
}

function selectDataFormatterInfo(cell, row) {
  return <span>{row.value.additional_information}</span>
}

function selectDataFormatterModifiedBy(cell, row) {

    if (row.users.items && row.users.items.length > 0)
    {
      var value = row.users.items.find(option => option.id == row.value.modified_by)
      if (value)
      {
        return (
          <span>{ value.label }</span>
        );
      }
      else {
        return (<span></span>);
      }
    }
    else {
      return (<span></span>);
    }
}


const columnsRead = [{
  dataField: 'value.reservation_id',
  text: '#',
  filter: textFilter({placeholder: 'Filtrer...'})
}, {
  dataField: 'value.project',
  text: 'Project',
  formatter: projectNumberFormatter,
  filter: textFilter({placeholder: 'Filtrer...'}),
  filterValue: (cell, row) => row.projects && row.projects.items.find(option => option.value == cell).label
}, {
  dataField: 'value.travelers',
  text: 'Voyageurs',
  editable: false,
  formatter: travelersFormatter,
  headerStyle: { width: '22%' },
  filter: textFilter({placeholder: 'Filtrer...'}),
  filterValue: (cell, row) => { console.log(cell); var value=""; for (var key in cell) { console.log(cell[key]); value += cell[key].label } ; console.log(value); return value; }
}, {
  dataField: 'value.additional_information',
  text: 'Info à ajouter',
  editable: false,
  formatter: selectDataFormatterInfo,
  filter: textFilter({placeholder: 'Filtrer...'})
}, {
  dataField: 'value.notes',
  text: 'Notes spéciales',
  editable: false,
  formatter: selectDataFormatterNotes,
  filter: textFilter({placeholder: 'Filtrer...'})
}, {
  dataField: 'value.submitted_by',
  text: 'Demandé par',
  formatter: selectDataFormatterSubmittedBy,
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
      <TableSelect options={row.users.items} { ...editorProps } value={ row.value } />
  ),
  filter: textFilter({placeholder: 'Filtrer...'}),
  filterValue: (cell, row) => row.users && row.users.items.find(option => option.id == cell).label
}, {
  dataField: 'value.submitted_on',
  text: "Date de soumission",
  filter: dateFilter({placeholder: 'Filtrer...'})
}, {
  dataField: 'value.submitted_on',
  text: "",
  filter: dateFilter({placeholder: 'Filtrer...'})
},{
  dataField: 'value.modified_notes',
  text: 'Raison du changement',
  editable: false,
  formatter: selectDataFormatterModifiedNotes,
  filter: textFilter({placeholder: 'Filtrer...'})
}, {
  dataField: 'value.modified_by',
  text: 'Modifié par',
  formatter: selectDataFormatterModifiedBy,
  editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
      <TableSelect options={row.users.items} { ...editorProps } value={ row.value } />
  ),
  filter: textFilter({placeholder: 'Filtrer...'}),
  filterValue: (cell, row) => row.users && row.users.items.find(option => option.id == cell).label
}, {
  dataField: 'value.modified_on',
  text: "Date de modification",
  formatter: (cell, row) => {
    let dateObj = row.value.modified_on;
    if ( dateObj == null )
    {
      return null
    }
    else {
      dateObj = new Date(row.value.modified_on);
    }
    return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
  },
  filter: dateFilter({placeholder: 'Filtrer...'})
},{
  dataField: 'value.destinations',
  text: 'Destinations',
  editable: false,
  filter: textFilter({placeholder: 'Filtrer...'}),
  formatter: destinationsFormatter,
},{
  dataField: 'value.completed',
  text: 'Réservation completée',
  editable: false,
  formatter: completedFormatter,
  filter: selectFilter({placeholder: 'Filtrer...', options: {true: 'Yes', false: 'No'}})
}];

const columnsWrite = [{
  dataField: 'value.archived',
  text: 'Compléter ou modifier (OOPS)',
  editable: false,
  formatter: editLinkFormatter
}]

const remote={
  filter: true,
  pagination: true,
  sort: false,
  cellEdit: false
}

class AllReservations extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        reservations: []
      };
    }

  componentDidMount() {
    this.props.getAll(1, 10, "");
    this.props.getAllDivisions();
    this.props.getAllProjects();
    this.props.getAllUsers();
    this.props.getAllAirports();
    this.props.getAllCompanies();
    this.props.getAllGLNumbers();
  }

  onTableChange(type, newState) {
    console.log(type)
    console.log(newState)
    if(type==="cellEdit")
    {
      this.props.handleChange(newState.cellEdit);
    }
    else if (type==="pagination" || type==="filter") {
      console.log(type)
      console.log(newState)
      this.props.getAll(newState.page, newState.sizePerPage, newState.filters)
    }
    return true;
  }

  render() {
    console.log(this.props.isAuthenticated)
    console.log(this.props.sessionData.user.is_readonly)
    console.log("READER")
    var columns = columnsRead;
    console.log(columns)
    if (!this.props.sessionData.user.is_readonly) {
      console.log("write columns")
      columns = columns.concat(columnsWrite);
    }
    console.log(columns)
    var data = []
    if (this.props.reservations && this.props.reservations.items && this.props.reservations.items.length > 0 )
    {
      for (var i in this.props.reservations.items)
      {
        data.push({reservation_id: this.props.reservations.items[i].reservation_id, value:this.props.reservations.items[i], projects:this.props.projects, airports:this.props.airports, users:this.props.users})
      }
    }
    data.sort(function(a, b){return b.reservation_id - a.reservation_id});
    var pageFactory = paginationFactory({ page:this.props.reservations.pagination.page, sizePerPage:this.props.reservations.pagination.sizePerPage, totalSize:this.props.reservations.pagination.totalSize })
    return (
      <div style={{marginLeft:"5%", marginRight:"5%"}}>
        { this.props.isAuthenticated && !this.props.sessionData.user.is_readonly && 
        <><Row>
          <Col md="3" xs="6" style={{marginBottom: "10px"}}><NewReservationModal buttonLabel="Nouvelle réservation" /></Col><Col md="1" xs="6"></Col><Col md="8" xs="12"></Col>
        </Row>
        <br/></> }
        { this.props.isAuthenticated && this.props.sessionData.user.is_readonly && 
        <><Row>
          <Col md="3" xs="6" style={{marginBottom: "10px"}}><RequestReservationModal buttonLabel="Demande de voyagement" /></Col><Col md="1" xs="6"></Col><Col md="8" xs="12"></Col>
        </Row>
        <br/></> }
        <BootstrapTable pagination={ pageFactory } filter={ filterFactory() } rowStyle={rowStyle} bootstrap4={true} bordered={false} classes={"table-responsive"} keyField='reservation_id' remote={remote} onTableChange={this.onTableChange.bind(this)} data={ data } columns={ columns } />

      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (edit) => {
      dispatch(reservationActions.update(edit));
    },
    getAll: (page, numPerPage, searchQuery) => {
      dispatch(reservationActions.getAll(page, numPerPage, searchQuery));
    },
    getAllDivisions: () => {
      dispatch(divisionActions.getAll());
    },
    getAllProjects: () => {
      dispatch(projectActions.getAll());
    },
    getAllGLNumbers: () => {
      dispatch(GLNumberActions.getAll());
    },
    getAllAirports: () => {
      dispatch(airportActions.getAll());
    },
    getAllUsers: () => {
      dispatch(userActions.getAll());
    },
    getAllCompanies: () => {
      dispatch(companyActions.getAll());
    }
  }
}

function mapStateToProps(state){
    return {
        users: state.users,
        airports: state.airports,
        projects: state.projects,
        reservations: state.reservations, 
        sessionData: state.session,
        isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
    }
}

const connectedAllReservations = connect(mapStateToProps, mapDispatchToProps)(AllReservations);
export { connectedAllReservations as AllReservations };
