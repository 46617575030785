import React from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Row, Col } from 'reactstrap';
import { userActions } from '../_actions';
import { Link } from 'react-router-dom';
import { error as toastError, success as toastSuccess } from 'react-toastify-redux';

class ForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { email } = this.state;
        if (!email) {
          this.props.dispatch(toastError("You need to provide an email."));
        }
        if (email) {
          const credentials = { email: email }
          console.log("TESTING CREDENTIALS")
          console.log(credentials)
          this.props.dispatch(userActions.forgotPassword(credentials));
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { email, submitted } = this.state;
        return (
            <div className="col-md-6 offset-md-3">
            <h2>Forgot your password</h2>
              <form onSubmit={this.handleSubmit} name="login_user_form" id="login_form">
                  <fieldset>
                     <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                        <input type="text" className="form-control" placeholder="Addresse Courriel" name="email" value={email} onChange={this.handleChange} />
                      </div>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Button type="submit" color="success">Récupérer votre mot de passe</Button>
                          </Col>
                          <Col>
                            <Button outline color="secondary" to="/login">Connecter</Button>
                          </Col>
                        </Row>
                      </FormGroup>
                  </fieldset>
              </form>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {};
}

const connectedForgotPasswordPage = connect(mapStateToProps)(ForgotPasswordPage);
export { connectedForgotPasswordPage as ForgotPasswordPage };
