import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, FormFeedback, Table } from 'reactstrap';
import { reservationActions } from '../_actions';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import { FormikSelect as Select, FormikDatePicker, FormikSelectNested as NestedSelect, CCField } from '../_components';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class CompleteReservationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.completeReservation = this.completeReservation.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  completeReservation(values, { props, setSubmitting }) {
      if(values.cc) {
        if(Array.isArray(values.cc)) {
            this.props.dispatch(reservationActions.update({...values, cc:values.cc.join(","), completed:true}))
        }
        else {
             this.props.dispatch(reservationActions.update({...values, cc:values.cc, completed:true}))
        }
      }
      else {
        this.props.dispatch(reservationActions.update({...values, values, completed:true}))
      }

      setSubmitting(false);
      this.toggle();
  }

  render() {
    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>Compléter une réservation</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={this.completeReservation}
            initialValues={this.props.initialValues}
            validationSchema={Yup.object().shape({
              project: Yup.number()
                .required('Le numéro de projet est requis.'),
              fcnq_division: Yup.number()
                .required('La division FCNQ est requise.'),
              gl_number: Yup.number()
                .required('Le numéro GL est requis.'),
              company: Yup.number()
                .required('La compagnie du voyageur est requise.'),
              cc: Yup.string(),
              destinations : Yup.array().of(
                Yup.object().shape({
                  departure: Yup.string()
                    .required("Vous devez entrer l'emplacement de départ"), // these constraints take precedence
                  arrival: Yup.string()
                    .required('Vous devez entrer la destination'), // these constraints take precedence
                  departure_date: Yup.date()
                    .required('Vous devez entrer la date de départ'), // these constraints take precedence
                })
              )
              .required('Vous devez entrer au moins une destination') // these constraints are shown if and only if inner constraints are satisfied
              .min(1, 'Vous devez entrer au moins une destination'),
              travelers: Yup.array().of(
                Yup.object().shape({
                  value: Yup.string()
                    .required('Vous devez choisir un voyageur') // these constraints take precedence
                })
              )
              .required('Vous devez entrer au moins un voyageur') // these constraints are shown if and only if inner constraints are satisfied
              .min(1, 'Vous devez entrer au moins un voyageur')
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label="Numéro de projet" options={this.props.projects.items} name="project" id="project" value={values.project}/>
                  {errors.project && touched.project && <div className="input-feedback" style={{color:"red"}}>{errors.project}</div>}
                </FormGroup>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label="Division FCNQ construction inc." options={this.props.divisions.items} name="fcnq_division" id="fcnq_division" value={values.fcnq_division}/>
                  {errors.fcnq_division && touched.fcnq_division && <div className="input-feedback" style={{color:"red"}}>{errors.fcnq_division}</div>}
                </FormGroup>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label="Numéro GL" options={this.props.gl_numbers.items} name="gl_number" id="gl_number" value={values.gl_number}/>
                  {errors.gl_number && touched.gl_number && <div className="input-feedback" style={{color:"red"}}>{errors.gl_number}</div>}
                </FormGroup>

                <FieldArray
                  name="travelers"
                  render={arrayHelpers => (
                    <Table>
                      <thead>
                        <tr>
                          <th>Voyageur</th>
                        </tr>
                      </thead>
                      <tbody>
                      {values.travelers && values.travelers.length > 0 &&
                        values.travelers.map((traveler, index) => (
                          <tr key={index}>
                            <td>
                              <Field handleBlur={handleBlur}  onSelectUser={(email, email_2) => {email?setFieldValue("cc", values.cc + (values.cc?",":"") + email + email_2 ):console.log("no email")}} onChange={(e) => { handleChange(e);}} component={NestedSelect} options={this.props.users.items} name={`travelers[${index}]`} id={`travelers[${index}]`} value={traveler.value}/>
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                      <div>
                        { values.travelers && values.travelers.length > 1 && <button
                          type="button"
                          onClick={() => arrayHelpers.pop()} // remove a friend from the list
                        >
                          -
                        </button>}
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push('')} // insert an empty string at a position
                        >
                          +
                        </button>
                      </div>
                    </Table>
                  )}
                />

                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label="Compagnie du voyageur" options={this.props.companies.items} name="company" id="company" value={values.company}/>
                  {errors.company && touched.company && <div className="input-feedback" style={{color:"red"}}>{errors.company}</div>}
                </FormGroup>

                <FieldArray
                  name="destinations"
                  render={arrayHelpers => (
                    <Table>
                      <thead>
                        <tr>
                          <th>De</th>
                          <th>À</th>
                          <th>Date de départ</th>
                          <th>Hôtel Requis</th>
                        </tr>
                      </thead>
                      <tbody>
                      {values.destinations && values.destinations.length > 0 &&
                        values.destinations.map((destination, index) => (
                          <tr key={index}>
                            <td>
                              <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={NestedSelect} options={this.props.airports.items} name={`destinations[${index}].departure`} id={`destinations[${index}].departure`} value={destination.departure.value}/>
                            </td>
                            <td>
                              <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={NestedSelect} options={this.props.airports.items} name={`destinations[${index}].arrival`} id={`destinations[${index}].departure`} value={destination.arrival.value}/>
                            </td>
                            <td>
                              <Field component={FormikDatePicker}  handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} name={`destinations[${index}].departure_date`} id={`destinations[${index}].departure_date`} />
                            </td>
                            <td>
                              <Input onChange={(e) => {handleChange(e)}} tag={Field} type="checkbox" checked={destination.hotel_required} value={destination.hotel_required} name={`destinations[${index}].hotel_required`} id={`destinations[${index}].hotel_required`} />
                            </td>
                          </tr>
                        ))
                      }
                      </tbody>
                      <div>
                        {values.destinations && values.destinations.length > 1 && <button
                          type="button"
                          onClick={() => arrayHelpers.pop()} // remove a friend from the list
                        >
                          -
                        </button>}
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push({departure:"", arrival:"", departure_date:Date.now(), hotel_required:false})} // insert an empty string at a position
                        >
                          +
                        </button>
                      </div>
                    </Table>
                  )}
                />
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={CCField} label="CC - SVP envoyer un copie aux courriels suivants" options={this.props.cc} name="cc" id="cc" value={values.cc}/>
                  {errors.cc && touched.cc && <div className="input-feedback" style={{color:"red"}}>{errors.cc}</div>}
                </FormGroup>
                <Row>
                  <Col xs="6" className="text-center">
                    <Button type="submit" color="primary" onClick={(e) => {handleSubmit(e);}} disabled={isSubmitting}>Compléter</Button>
                  </Col>
                  <Col xs="6" className="text-center" >
                    <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                  </Col>
                </Row>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { airports, users, companies, projects, divisions, gl_numbers } = state;
  return {
      airports: airports,
      users: users,
      companies: companies,
      projects: projects,
      divisions: divisions,
      gl_numbers: gl_numbers,
  };
}

const connectedCompleteReservationModal = connect(mapStateToProps)(CompleteReservationModal);
export { connectedCompleteReservationModal as CompleteReservationModal };
