import React from 'react'
import ReactSelect, { Option, ReactSelectProps } from 'react-select'
import { FieldProps } from 'formik'

const singleValueStyle = {
  singleValue: (styles, { data }) => ({ ...styles, overflow: 'visible' }),
};

class Select extends React.Component {

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.onUpdate(selectedOption.value)
  }

  getValue() {
    return this.props.options ? this.props.options.find(option => option.value === this.props.value).value : "failed"
  }

  render() {
    return (
      <ReactSelect
          styles={{width:"auto"}}
          value={this.props.options ? this.props.options.find(option => option.value === this.props.value) : {label:"failed", value:"failed"}}
          onChange={this.handleChange}
          options={this.props.options}
          styles={singleValueStyle}
        />
		)
	}
}

export { Select };
