import { divisionConstants } from '../_constants';
import { divisionService } from '../_services';
import { error as toastError} from 'react-toastify-redux';
import { history } from '../_helpers';

export const divisionActions = {
    getAll,
    createNew,
    update,
    delete: _delete
  };


function getAll() {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        divisionService.getAll(1, 10, "")
            .then(
                divisions => {
                    console.log("THEN SUCCESS");
                    dispatch(success(divisions))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: divisionConstants.GETALL_REQUEST } }
    function success(divisions) { return { type: divisionConstants.GETALL_SUCCESS, divisions } }
    function failure(error) { return { type: divisionConstants.GETALL_FAILURE, error } }
}

function createNew(division)  {
    return dispatch => {
        dispatch(request(division));

        divisionService.create(division)
            .then(
                division => {
                    dispatch(success(division));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: divisionConstants.CREATE_REQUEST, division } }
    function success(id) { return { type: divisionConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: divisionConstants.CREATE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return divisionService.update(edit)
            .then(
                division => {
                    dispatch(success(division));
                    return division;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: divisionConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: divisionConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: divisionConstants.UPDATE_FAILURE, id, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        divisionService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: divisionConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: divisionConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: divisionConstants.DELETE_FAILURE, id, error } }
}
