import { projectConstants } from '../_constants';
import { projectService } from '../_services';
import { error as toastError} from 'react-toastify-redux';
import { history } from '../_helpers';

export const projectActions = {
    getAll,
    createNew,
    update,
    delete: _delete
  };


function getAll() {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        projectService.getAll()
            .then(
                projects => {
                    console.log("THEN SUCCESS");
                    dispatch(success(projects))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: projectConstants.GETALL_REQUEST } }
    function success(projects) { return { type: projectConstants.GETALL_SUCCESS, projects } }
    function failure(error) { return { type: projectConstants.GETALL_FAILURE, error } }
}

function createNew(project)  {
    return dispatch => {
        dispatch(request(project));

        projectService.create(project)
            .then(
                project => {
                    dispatch(success(project));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: projectConstants.CREATE_REQUEST, project } }
    function success(id) { return { type: projectConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: projectConstants.CREATE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return projectService.update(edit)
            .then(
                project => {
                    dispatch(success(project));
                    return project;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: projectConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: projectConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: projectConstants.UPDATE_FAILURE, id, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        projectService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: projectConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: projectConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: projectConstants.DELETE_FAILURE, id, error } }
}
