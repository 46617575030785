import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import { error as toastError } from 'react-toastify-redux';
import { store, history } from '../_helpers';

export const airportService = {
    getAll,
    update,
    create,
    delete: _delete
};

function getAll() {

    const config = {
      headers: { ...authHeader() }
    };

    return axios.get('/airports', config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load airports. Please try again later.")); throw "failure";});
}

function get(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/airport/' + id, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}

function update(edit) {
    const config = {
      headers: { ...authHeader() }
    };

    return axios.post('/airport', edit, config).then(handleResponse).catch(error => {console.log(error.response)});
}

function create(values) {
    const config = {
      headers: { ...authHeader() }
    };
    console.log(values);
    return axios.put('/airport', values, config).then(handleResponse).catch(error => {console.log(error.response)});
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete('/airport/' + id, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}
