import { store } from '../_helpers';

export function authHeader() {
    // return authorization header with jwt token
    const state = store.getState()
    const lsUser = JSON.parse(localStorage.getItem('user'));
    var token = null;
    if (state.session && state.session.user && state.session.user.authentication_token)
    {
      token = state.session.user.authentication_token
    }
    else {
      token = lsUser.authentication_token
    }

    return { 'Auth-Token': token };
}

export function refreshHeader() {
    // return authorization header with jwt token
    const lsUser = JSON.parse(localStorage.getItem('user'));

    return { 'Auth-Token': lsUser.refresh_token };

}
