import {userService} from '../_services';
import {userConstants} from '../_constants';
import moment from "moment";
import jwtDecode from "jwt-decode";

export function jwt({ dispatch, getState }) {

    return (next) => (action) => {

        if (typeof action === 'function') {

                const state = getState();
                const lsUser = JSON.parse(localStorage.getItem('user'));

                var token = null;
                if (state.session && state.session.user && state.session.user.authentication_token)
                {
                  token = state.session.user.authentication_token
                }
                else if ( lsUser && lsUser.authentication_token ) {
                  token = lsUser.authentication_token
                }
                  if (token ) {
                      // decode jwt so that we know if and when it expires
                      var tokenExpiration = jwtDecode(token).exp;
                      //var tokenExpiration = "";
                      if (tokenExpiration && (moment(tokenExpiration) - moment(Date.now()/1000) < 100)) {

                          // make sure we are not already refreshing the token

                          if (!state.session.refreshing) {
                              console.log("STARTING TO REFRESH")
                              return userService.refreshToken(dispatch).then(() => next(action));
                          } else {
                              console.log("CURRENTLY REFRESHING")
                              return getState().session.freshTokenPromise.then(() => next(action));
                          }
                      }
                  }
        }
        return next(action);
    };
}
