export const companyConstants = {

    GETALL_REQUEST: 'COMPANIES_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMPANIES_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMPANIES_GETALL_FAILURE',

    DELETE_REQUEST: 'COMPANIES_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMPANIES_DELETE_SUCCESS',
    DELETE_FAILURE: 'COMPANIES_DELETE_FAILURE',

    CREATE_REQUEST: 'COMPANIE_CREATE_REQUEST',
    CREATE_SUCCESS: 'COMPANIE_CREATE_SUCCESS',
    CREATE_FAILURE: 'COMPANIE_CREATE_FAILURE',
    UPDATE_REQUEST: 'COMPANIE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'COMPANIE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'COMPANIE_UPDATE_FAILURE',

};
