export const GLNumberConstants = {

    GETALL_REQUEST: 'GL_NUMBERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'GL_NUMBERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'GL_NUMBERS_GETALL_FAILURE',

    DELETE_REQUEST: 'GL_NUMBER_DELETE_REQUEST',
    DELETE_SUCCESS: 'GL_NUMBER_DELETE_SUCCESS',
    DELETE_FAILURE: 'GL_NUMBER_DELETE_FAILURE',

    CREATE_REQUEST: 'GL_NUMBER_CREATE_REQUEST',
    CREATE_SUCCESS: 'GL_NUMBER_CREATE_SUCCESS',
    CREATE_FAILURE: 'GL_NUMBER_CREATE_FAILURE',
    UPDATE_REQUEST: 'GL_NUMBER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'GL_NUMBER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'GL_NUMBER_UPDATE_FAILURE',

};
