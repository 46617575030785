import { reservationConstants } from '../_constants';
import { reservationService } from '../_services';
import { error as toastError} from 'react-toastify-redux';
import { history } from '../_helpers';

export const reservationActions = {
    getAll,
    refreshAll,
    updateSearch,
    updatePagination,
    createNew,
    update,
    charter,
    delete: _delete
  };

function _delete(id, reason) {
    return dispatch => {
        dispatch(request(id, reason));

        reservationService.delete(id, reason)
            .then(
                user => {
                    dispatch(success(id, reason));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: reservationConstants.DELETE_REQUEST, id, reason } }
    function success(id) { return { type: reservationConstants.DELETE_SUCCESS, id, reason } }
    function failure(id, error) { return { type: reservationConstants.DELETE_FAILURE, id, error } }
}

function charter(id) {
    return dispatch => {
        dispatch(request(id));

        reservationService.charter(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: reservationConstants.CHARTER_REQUEST, id } }
    function success(id) { return { type: reservationConstants.CHARTER_SUCCESS, id } }
    function failure(id, error) { return { type: reservationConstants.CHARTER_FAILURE, id, error } }
}

function getAll(page, numPerPage, filters) {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        reservationService.getAll(page, numPerPage, filters)
            .then(
                reservations => {
                    console.log("THEN SUCCESS");
                    dispatch(success(reservations))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: reservationConstants.GETALL_REQUEST } }
    function success(reservations) { return { type: reservationConstants.GETALL_SUCCESS, reservations } }
    function failure(error) { return { type: reservationConstants.GETALL_FAILURE, error } }
}

function changeFilter(filters) {
    return { type: reservationConstants.CHANGE_FILTER, filters };
}

function refreshAll(page, sizePerPage, searchQuery, filters) {
    return dispatch => {
        reservationService.getAll(page, sizePerPage, searchQuery, filters)
            .then(
                reservations => dispatch(success(reservations)),
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function success(reservations) { return { type: reservationConstants.GETALL_SUCCESS, reservations } }
    function failure(error) { return { type: reservationConstants.GETALL_FAILURE, error } }
}

function updateSearch(searchQuery) {
    return { type: reservationConstants.UPDATE_SEARCH, searchQuery }
}

function updatePagination(page, sizePerPage) {
    return { type: reservationConstants.UPDATE_PAGINATION, page, sizePerPage }
}

function createNew(reservation)  {
    return dispatch => {
        dispatch(request(reservation));

        reservationService.create(reservation)
            .then(
                reservation => {
                    dispatch(success(reservation));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: reservationConstants.CREATE_REQUEST, reservation } }
    function success(id) { return { type: reservationConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: reservationConstants.CREATE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return reservationService.update(edit)
            .then(
                reservation => {
                    dispatch(success(reservation));
                    return reservation;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: reservationConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: reservationConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: reservationConstants.UPDATE_FAILURE, id, error } }
}
