export const reservationConstants = {
    GETALL_REQUEST: 'RESERVATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'RESERVATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'RESERVATIONS_GETALL_FAILURE',
    DELETE_REQUEST: 'RESERVATION_DELETE_REQUEST',
    DELETE_SUCCESS: 'RESERVATION_DELETE_SUCCESS',
    DELETE_FAILURE: 'RESERVATION_DELETE_FAILURE',
    CREATE_REQUEST: 'RESERVATION_CREATE_REQUEST',
    CREATE_SUCCESS: 'RESERVATION_CREATE_SUCCESS',
    CREATE_FAILURE: 'RESERVATION_CREATE_FAILURE',
    UPDATE_REQUEST: 'RESERVATION_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'RESERVATION_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'RESERVATION_UPDATE_FAILURE',
    CHARTER_REQUEST: 'RESERVATION_CHARTER_REQUEST',
    CHARTER_SUCCESS: 'RESERVATION_CHARTER_SUCCESS',
    CHARTER_FAILURE: 'RESERVATION_CHARTER_FAILURE',
    SELECT: 'RESERVATION_SELECT',
    UPDATE_SEARCH: 'RESERVATION_UPDATE_SEARCH',
    UPDATE_PAGINATION: 'RESERVATION_UPDATE_PAGINATION',
};
