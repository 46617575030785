export const userConstants = {

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',
    RETRIEVE_TOKEN: 'USERS_RETRIEVE_TOKEN',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'USERS_FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'USERS_FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'USERS_FORGOT_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

    REFRESHING_TOKEN: 'USERS_REFRESHING_TOKEN',
    DONE_REFRESHING_TOKEN: 'USERS_DONE_REFRESHING_TOKEN',

    CREATE_REQUEST: 'USER_CREATE_REQUEST',
    CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
    CREATE_FAILURE: 'USER_CREATE_FAILURE',
    UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
};
