import { combineReducers } from 'redux';
import { airports } from './airports.reducer';
import { companies } from './companies.reducer';
import { divisions } from './divisions.reducer';
import { gl_numbers } from './gl_numbers.reducer';
import { projects } from './projects.reducer';
import { users } from './users.reducer';
import { reservations } from './reservations.reducer';
import { session } from './session.reducer';
import { toastsReducer as toasts } from 'react-toastify-redux';

const rootReducer = combineReducers({
  session,
  users,
  reservations,
  toasts,
  airports,
  divisions,
  projects,
  gl_numbers,
  companies,
});

export default rootReducer;
