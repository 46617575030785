import { reservationConstants, medicationConstants, conditionConstants, recommendationConstants } from '../_constants';

export function reservations(state = {pagination: {totalSize: 10,sizePerPage: 10,page: 1}}, action) {
  switch (action.type) {
    case reservationConstants.GETALL_REQUEST:
      return {
        loading: true,
        filters: state.filters,
        pagination: state.pagination
      };
    case reservationConstants.GETALL_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        filters:state.filters,
        items: action.reservations.data.reservations,
        pagination: {
            ...state.pagination,
            totalSize: action.reservations.data.totalSize,
            page: action.reservations.data.page,
            sizePerPage: action.reservations.data.sizePerPage,
            filters: action.reservations.data.filters,
        }
      };
    case reservationConstants.GET_FAILURE:
      return {
        ...state,
        currentreservation: {}
      };
    case reservationConstants.UPDATE_SEARCH:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        pagination: {
            ...state.pagination,
            searchQuery: action.searchQuery
        }
      };
    case reservationConstants.UPDATE_PAGINATION:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        pagination: {
            ...state.pagination,
            page: action.page,
            sizePerPage: action.sizePerPage
        }
      };
    case reservationConstants.CREATE_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: [
            ...state.items,
            action.id.data
        ]
      };
    case reservationConstants.UPDATE_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (reservation, index) => {
            if(reservation.reservation_id == action.id.data.reservation_id){
              return { ...action.id.data }
            }
            return reservation
          })
      };
    case reservationConstants.DELETE_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (reservation, index) => {
            if(reservation.reservation_id === action.id){
              return { ...reservation, archived:true, reason:action.reason }
            }
            return reservation
          })
      };
    default:
      return state
  }
}
