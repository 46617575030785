import { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { userActions } from '../_actions';
import React from 'react';
import { Redirect } from 'react-router-dom';

class LogoutPage extends Component {

  componentWillMount() {
    console.log("LOGOUT WILL MOUNT")
    this.props.dispatch(userActions.logout());
    localStorage.clear();
  }

  render() {
    return <div>Déconnexion...</div>
  }
}

const mapStateToProps = state => ({
  sessionData: state.session,
  isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
})

const connectedLogoutPage = connect(mapStateToProps)(LogoutPage);
export { connectedLogoutPage as LogoutPage };
