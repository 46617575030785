import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input } from 'reactstrap';
import { reservationActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-free-solid'

class DeleteReservationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      reason: ""
    };

    this.toggle = this.toggle.bind(this);
    this.deleteReservation = this.deleteReservation.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
      console.log("ON CHANGE");
      console.log(event);
      this.setState({reason: event.target.value});
    }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  deleteReservation() {
    console.log("DELETE");
    console.log(this.props.reservation_id);
    this.props.delete(this.props.reservation_id, this.state.reason);
    this.toggle();
  }

  render() {
    return (
      <div>
        <Button color="danger" className="full-width" onClick={this.toggle}><FontAwesomeIcon icon={faTimes} color="white" size="lg" /></Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>Delete Reservation</ModalHeader>
          <ModalBody>
            <Label for="reason">Pour quelle raison voulez-vous annuler cette réservation?</Label>
            <Input onChange={this.handleChange} id="reason" name="reason" type="text" value={this.state.reason}/>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button color="danger" onClick={this.deleteReservation}>Annuler la réservation</Button>
              </Col>
              <Col xs="6" className="text-center" >
                <Button color="secondary" onClick={this.toggle}>Retour</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    delete: (reservation_id, reason) => {
      dispatch(reservationActions.delete(reservation_id, reason))
    }
  }
}

const connectedDeleteReservationModal = connect(mapStateToProps, mapDispatchToProps)(DeleteReservationModal);
export { connectedDeleteReservationModal as DeleteReservationModal };
