import React, { Component } from 'react';
import '../css/App.css';
import { history } from '../_helpers';
import { userActions } from '../_actions';
import { Router, Route, Redirect } from 'react-router-dom';
import { LoginPage, ChangePasswordPage, ForgotPasswordPage, ResetPasswordPage, LogoutPage } from '../AuthPages';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/browser';
import { ToastContainer, error as toastError, success as toastSuccess  } from 'react-toastify-redux';
import { Navigation, PrivateRoute, PrivateAdminRoute, LoginRoute } from '../_components';
import { connect } from 'react-redux';
import { AllReservations } from '../Reservation';
import { AllAirports } from '../Airports';
import { AllUsers } from '../Users';
import { AllDivisions } from '../Divisions';
import { AllGLNumbers } from '../GLNumbers';
import { AllProjects } from '../Projects';
import { AllCompanies } from '../Companies';

Sentry.init({
  dsn: "https://6c6750aed2c241bc989e641c9cda1601@sentry.io/1358046"
 });

class App extends Component {
  constructor(props) {
      super(props);

      const { dispatch } = this.props;
  }

  render() {

    const lsUser = JSON.parse(localStorage.getItem('user'));

    return (
      <div className="App">
            <Router history={history}>
              <div>
                <br/>
                <div>
                  {alert.message &&
                      <div className={`alert ${alert.type}`}>{alert.message}</div>
                  }
                      <div>
                        <PrivateRoute exact path="/" component={() => <Redirect to="/reservations" />}/>
                        <PrivateRoute exact path="/reservations" component={AllReservations} />
                        <PrivateRoute exact path="/airports" component={AllAirports} />
                        <PrivateRoute exact path="/users" component={AllUsers} />
                        <PrivateRoute exact path="/companies" component={AllCompanies} />
                        <PrivateRoute exact path="/projects" component={AllProjects} />
                        <PrivateRoute exact path="/gl_numbers" component={AllGLNumbers} />
                        <PrivateRoute exact path="/divisions" component={AllDivisions} />
                        <LoginRoute path="/login" component={LoginPage} />
                        <Route path="/forgot_password" component={ForgotPasswordPage} />
                        <PrivateRoute path="/logout" component={LogoutPage} />
                        <LoginRoute path="/reset_password/:passwordResetCode" component={ResetPasswordPage} />
                        <PrivateRoute lsUser={ lsUser } path="/change_password" component={ChangePasswordPage} />
                      </div>
                </div>
              </div>
            </Router>
            <ToastContainer />

          </div>
    );
  }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
        sessionData: state.session,
        isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
