import React from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Row, Col } from 'reactstrap';
import { userActions } from '../_actions';
import { Link } from 'react-router-dom';
import { error as toastError, success as toastSuccess } from 'react-toastify-redux';

class ChangePasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            newPassword: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { password, newPassword } = this.state;

        if (!password) {
          this.props.dispatch(toastError("You need to provide your current password."));
        }

        if (!newPassword) {
          this.props.dispatch(toastError("You need to provide a new password."));
        }

        if (password && newPassword) {
          const credentials = { password: password, new_password:newPassword }
          console.log("TESTING CREDENTIALS")
          console.log(credentials)
          this.props.dispatch(userActions.changePassword(credentials));
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { password, newPassword, submitted } = this.state;
        return (
            <div>
              <div className="background"></div>
                <div className="login_with_logo">
                    <div className="login-panel card card-primary">
                        <div className="card-body">
                            <h1 className="card-title" style={{textAlign: "center", color: "rgb(33, 150, 243)", fontWeight: 300}}>FCNQ</h1>
                            <form onSubmit={this.handleSubmit} name="login_user_form" id="login_form">
                                <fieldset>
                                    <FormGroup className={(submitted && !password ? ' has-error' : '')}>
                                      <input type="password" className="form-control" placeholder="Mot de passe" name="password" value={password} onChange={this.handleChange} />
                                    </FormGroup>
                                    <FormGroup className={(submitted && !newPassword ? ' has-error' : '')}>
                                      <input type="password" className="form-control" placeholder="Nouveau mot de passe" name="newPassword" value={newPassword} onChange={this.handleChange} />
                                    </FormGroup>
                                    <FormGroup>
                                      <Row>
                                        <Col>
                                          <Button className="full-width" color="success">Changer votre mot de passe</Button>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                </fieldset>
                            </form>
                        </div>
                        <div style={{color:"white", textAlign:"center", width:"100%"}}><small>v1.2</small></div>
                    </div>
                </div>

              </div>
        );
    }
}


function mapStateToProps(state) {
    return {};
}

const connectedChangePasswordPage = connect(mapStateToProps)(ChangePasswordPage);
export { connectedChangePasswordPage as ChangePasswordPage };
