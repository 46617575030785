import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { Button, FormGroup, Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { error as toastError, success as toastSuccess } from 'react-toastify-redux';
import { Route, Redirect } from 'react-router-dom';
import plane from '../static/plane.png' // relative path to image

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
      console.log("LOGIN WILL MOUNT")
      const lsUser = JSON.parse(localStorage.getItem('user'));
      console.log("NOT AUTHENTICATED, TRYING TO REFRESH")
      console.log(lsUser)
      if (lsUser && lsUser.authentication_token)
      {
        this.props.dispatch(userActions.retrieveUser(lsUser))
      }
      this.props.dispatch(userActions.logout());
      localStorage.clear();
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { email, password } = this.state;
        if (!email) {
          this.props.dispatch(toastError("You need to provide an email."));
        }
        if (!password) {
          this.props.dispatch(toastError("You need to provide a password."));
        }
        if (email && password) {
          //const credentials = { email: email, password: password }
          this.props.dispatch(userActions.login({ email, password }));
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { email, password, submitted } = this.state;
        if (this.props.isAuthenticated) {
          return <Redirect to="/reservations" />
        }
        return (
          <div className="background">
            <div className="login_with_plane">
              <Card>
                <CardImg top width="100%" src={plane} alt="Connecter" />
                <CardBody>
                  <form name="form" onSubmit={this.handleSubmit}>
                      <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                          <label htmlFor="email">Courriel</label>
                          <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                          {submitted && !email &&
                              <div className="help-block">Le courriel est requis</div>
                          }
                      </div>
                      <FormGroup className={(submitted && !password ? ' has-error' : '')}>
                          <label htmlFor="password">Mot de passe</label>
                          <input type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                          {submitted && !password &&
                              <div className="help-block">Le mot de passe est requis</div>
                          }
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <Col>
                            <Button color="success">Login</Button>
                            {loggingIn &&
                                <img alt="Logging in..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                          </Col>
                        </Row>
                        <br/>
                        <div className="text-center">
                            <Link to="/forgot_password">Oublié votre mot de passe?</Link>
                        </div>
                      </FormGroup>
                  </form>
                </CardBody>
              </Card>
            </div>
          </div>
        );
    }
}

const mapStateToProps = state => ({
  sessionData: state.session,
  isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
})

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
