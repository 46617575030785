import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import './css/index.css';
import './css/App.css';
import './css/custom.scss';
import {App} from './App/App';
import axios from "axios";
import RichTextEditor from 'react-rte';
import registerServiceWorker, {unregister} from './utils/registerServiceWorker';

window.RichTextEditor = RichTextEditor

axios.defaults.baseURL = 'https://api.fcnqreservation.ca/';

//axios.defaults.baseURL = 'http://localhost:8006/';

unregister() ;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'));
registerServiceWorker();
