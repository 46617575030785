import React from 'react';
import { connect } from 'react-redux';
import { projectActions } from '../_actions';
import { NewProjectModal, DeleteProjectModal } from "../Projects"
import { Form, Row, Col } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

function deleteLinkFormatter(value, row, index, field) {
  return (
    <DeleteProjectModal className="full-width" color="danger" project_id={row.value}></DeleteProjectModal>
  );
}

function selectDataFormatterLabel(cell, row) {
  return <span>{row.label}</span>
}

const columns = [{
  dataField: 'label',
  text: 'Projet',
  editable: true
},{
  dataField: 'delete',
  text: 'Supprimer',
  editable: false,
  formatter: deleteLinkFormatter
}];

const remote={
  filter: false,
  pagination: false,
  sort: false,
  cellEdit: true
}

class AllProjects extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        projects: []
      };
    }

  componentDidMount() {
    this.props.getAllProjects();
  }

  onTableChange(type, newState) {
    if(type==="cellEdit")
    {
      this.props.handleChange(newState.cellEdit);
    }
    return true;
  }

  render() {
    return (
      <div style={{marginLeft:"5%", marginRight:"5%"}}>
        <Row>
          <Col md="3" xs="6" style={{marginBottom: "10px"}}><NewProjectModal buttonLabel="Nouveau projet" /></Col><Col md="1" xs="6"></Col>
        </Row>
        <br/>
        { this.props.projects && this.props.projects.items && this.props.projects.items.length > 0 &&
          <div className="table-responsive-sm">
            <BootstrapTable style={{ margin:"auto"}} bootstrap4={true} bordered={false} keyField='value' remote={remote} cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) } onTableChange={this.onTableChange.bind(this)} data={ this.props.projects.items } columns={ columns } />
          </div>
        }
      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (edit) => {
      dispatch(projectActions.update(edit));
    },
    getAllProjects: () => {
      dispatch(projectActions.getAll());
    }
  }
}

function mapStateToProps(state){
    return {
        projects: state.projects
    }
}

const connectedAllProjects = connect(mapStateToProps, mapDispatchToProps)(AllProjects);
export { connectedAllProjects as AllProjects };
