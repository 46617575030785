export const projectConstants = {

    GETALL_REQUEST: 'PROJECTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PROJECTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PROJECTS_GETALL_FAILURE',

    DELETE_REQUEST: 'PROJECTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'PROJECTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROJECTS_DELETE_FAILURE',

    CREATE_REQUEST: 'PROJECT_CREATE_REQUEST',
    CREATE_SUCCESS: 'PROJECT_CREATE_SUCCESS',
    CREATE_FAILURE: 'PROJECT_CREATE_FAILURE',
    UPDATE_REQUEST: 'PROJECT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PROJECT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PROJECT_UPDATE_FAILURE',

};
