import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, FormFeedback, Table } from 'reactstrap';
import { reservationActions } from '../_actions';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik'
import { FormikSelect as Select, FormikSelectWithOther as SelectWithOther, FormikDatePicker, CCField } from '../_components';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class RequestReservationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    console.log("constructor")
    this.yes_no_options = [{
      "label": "Oui",
      "value": true
    },
    {
      "label": "Non",
      "value": false
    }]
    this.toggle = this.toggle.bind(this);
    this.createNewReservation = this.createNewReservation.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  createNewReservation(values, { props, setSubmitting }) {
    console.log("CREATE NEW")
      setSubmitting(true);

      if(values.cc) {
        if(Array.isArray(values.cc)) {
            this.props.dispatch(reservationActions.createNew({...values, cc:values.cc.join(","), completed:false}))
        }
        else {
             this.props.dispatch(reservationActions.createNew({...values, cc:values.cc, completed:false}))
        }
      }
      else {
        this.props.dispatch(reservationActions.createNew({...values, completed:false}))
      }

      setSubmitting(false);
      this.toggle();
  }

  render() {
    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>Demande de voyagement</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={this.createNewReservation}
            initialValues={{
              new_employee: false,
              hiring_notice_sent: false,
              first_name: "",
              last_name: "",
              date_of_birth: new Date(),
              project: this.props.projects && this.props.projects[0] && this.props.projects[0].value,
              return_date: new Date(),
              gl_number: this.props.gl_numbers && this.props.gl_numbers[0] && this.props.gl_numbers[0].value,
              company: this.props.companies && this.props.companies[0] && this.props.companies[0].value,
              notes: "",
              additional_information: "",
              hotel_required: false,
              destinations: [{departure: this.props.airports && this.props.airports[0] && this.props.airports[0].value,arrival: this.props.airports && this.props.airports[0] && this.props.airports[0].value, departure_date: new Date(),hotel_required: false}],
              travelers: [{label:"", value:""}],
              cc: ""
          	}}
            validationSchema={Yup.object().shape({
              project: Yup.number()
                .required('Le numéro de projet est requis.'),
              gl_number: Yup.number()
                .required('Le numéro GL est requis.'),
              company: Yup.number()
                .required('La compagnie du voyageur est requise.'),
              notes: Yup.string(),
              cc: Yup.string(),
              destinations : Yup.array().of(
                Yup.object().shape({
                  departure: Yup.string()
                    .required("Vous devez entrer l'emplacement de départ"),
                  arrival: Yup.string()
                    .required('Vous devez entrer la destination'),
                  departure_date: Yup.date()
                    .required('Vous devez entrer la date de départ'),
                })
              )
              .required('Vous devez entrer au moins une destination')
              .min(1, 'Vous devez entrer au moins une destination')
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label="Est-ce un nouvel employé ?" options={this.yes_no_options} name="new_employee" id="new_employee" value={values.new_employee}/>
                  {errors.new_employee && touched.new_employee && <div className="input-feedback" style={{color:"red"}}>{errors.new_employee}</div>}
                </FormGroup>
                {values.new_employee && 
                <>
                  <div>Veuillez envoyer un courriel à <a href="mailto:construction.voyage@fcnq.ca">construction.voyage@fcnq.ca</a></div> avec les informations du voyageur (nom, prénom, date de naissance et courriel).
                </>
                }
                {!values.new_employee &&
                  <FormGroup>
                    <Label for="label">Sélectionner l'employé/e</Label>
                    <Field handleBlur={handleBlur} onSelectUser={(email, email_2) => {email?setFieldValue("cc", values.cc + (values.cc?",":"") + email + email_2 ):console.log("no email")}} onChange={(e) => {handleChange(e)}} component={Select} options={this.props.users.items} name={`travelers[0]`} id={`travelers[0]`} value={values.travelers[0]}/>
                    {errors.travelers && touched.travelers && errors.travelers[0] && <div className="input-feedback" style={{color:"red"}}>{typeof errors.travelers[0] === 'string' ? errors.travelers[0] : errors.travelers[0].value}</div>}
                  </FormGroup>
                }
                <FieldArray
                  name="destinations"
                  render={arrayHelpers => (
                    <Table>
                      <thead>
                        <tr>
                          <th>De</th>
                          <th>À</th>
                          <th>Date de départ</th>
                          <th>Hôtel Requis</th>
                        </tr>
                      </thead>
                      <tbody>
                      {values.destinations && values.destinations.length > 0 &&
                        values.destinations.map((destination, index) => (
                          <tr key={index}>
                            <td>
                              <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} options={this.props.airports.items} name={`destinations[${index}].departure`} id={`destinations[${index}].departure`} value={destination.departure}/>
                              {errors.destinations && touched.destinations && errors.destinations[index] && <div className="input-feedback" style={{color:"red"}}>{typeof errors.destinations[index] === 'string' ? errors.destinations[index] : errors.destinations[index].departure}</div>}

                            </td>
                            <td>
                              <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} options={this.props.airports.items} name={`destinations[${index}].arrival`} id={`destinations[${index}].departure`} value={destination.arrival}/>
                              {errors.destinations && touched.destinations && errors.destinations[index] && <div className="input-feedback" style={{color:"red"}}>{typeof errors.destinations[index] === 'string' ? errors.destinations[index] : errors.destinations[index].arrival}</div>}

                            </td>
                            <td>
                              <Field component={FormikDatePicker}  handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} name={`destinations[${index}].departure_date`} id={`destinations[${index}].departure_date`} />
                              {errors.destinations && touched.destinations && errors.destinations[index] && <div className="input-feedback" style={{color:"red"}}>{typeof errors.destinations[index] === 'string' ? errors.destinations[index] : errors.destinations[index].departure_date}</div>}

                            </td>
                            <td>
                              <Input onChange={(e) => {handleChange(e)}} tag={Field} type="checkbox" value={destination.hotel_required} name={`destinations[${index}].hotel_required`} id={`destinations[${index}].hotel_required`} />
                            </td>
                          </tr>

                        ))
                      }
                      </tbody>
                      <div>
                        {values.destinations && values.destinations.length > 1 && <button
                          type="button"
                          onClick={() => arrayHelpers.pop()}
                        >
                          -
                        </button>}
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push({departure: this.props.airports && this.props.airports[0] && this.props.airports[0].value,arrival: this.props.airports && this.props.airports[0] && this.props.airports[0].value, departure_date: new Date(),hotel_required: false})} // insert a new destination
                        >
                          +
                        </button>
                      </div>
                    </Table>
                  )}
                />
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={SelectWithOther} label="Compagnie du voyageur" options={this.props.companies.items} name="company" id="company" value={values.company}/>
                  {errors.company && touched.company && <div className="input-feedback" style={{color:"red"}}>{errors.company}</div>}
                </FormGroup>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={SelectWithOther} label="Numéro de projet" options={this.props.projects.items} name="project" id="project" value={values.project}/>
                  {errors.project && touched.project && <div className="input-feedback" style={{color:"red"}}>{errors.project}</div>}
                </FormGroup>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={SelectWithOther} label="Numéro GL" options={this.props.gl_numbers.items} name="gl_number" id="gl_number" value={values.gl_number}/>
                  {errors.gl_number && touched.gl_number && <div className="input-feedback" style={{color:"red"}}>{errors.gl_number}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="notes">Notes Spéciales (eg; allergies alimentaire, pick-up hôtel, annulation des réservation etc.) </Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.notes && touched.notes} tag={Field} type="text" value={values.notes} name="notes" id="notes" />
                  {errors.notes && touched.notes && <div className="input-feedback" style={{color:"red"}}>{errors.notes}</div>}
                </FormGroup>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={CCField} label="CC - SVP envoyer un copie aux courriels suivants" options={this.props.cc} name="cc" id="cc" value={values.cc}/>
                  {errors.cc && touched.cc && <div className="input-feedback" style={{color:"red"}}>{errors.cc}</div>}
                </FormGroup>
                <Row>
                  <Col xs="6" className="text-center">
                    <Button type="submit" color="primary" onClick={(e) => {console.log("handle submit");console.log(values);console.log(errors);console.log(e);handleSubmit(e);}} disabled={isSubmitting}>Ajouter</Button>
                  </Col>
                  <Col xs="6" className="text-center" >
                    <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                  </Col>
                </Row>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { airports, users, companies, projects, divisions, gl_numbers } = state;
  return {
      airports: airports,
      users: users,
      companies: companies,
      projects: projects,
      divisions: divisions,
      gl_numbers: gl_numbers,
  };
}

const connectedRequestReservationModal = connect(mapStateToProps)(RequestReservationModal);
export { connectedRequestReservationModal as RequestReservationModal };
