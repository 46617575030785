import React, { Component } from 'react';
import {Row, Col, Navbar, NavbarToggler, NavbarBrand, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div style={{borderBottom:"1px solid #e5e5e5"}} className="Navigation">
          <Row>
            <Navbar style={{marginLeft: "50px", marginRight:"50px", width:"100%"}} light expand="lg">
              <NavbarBrand style={{weight:"bold"}} href="/" >FCNQ</NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav navbar>
                  <NavItem>
                    <NavLink tag={Link} to="/reservations">Réservations</NavLink>
                  </NavItem>
                  { this.props.isAuthenticated && this.props.sessionData.user && this.props.sessionData.user.is_admin && !this.props.sessionData.user.is_readonly &&
                  <><NavItem>
                    <NavLink tag={Link} to="/users">Voyageurs</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/airports">Aéroports</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/gl_numbers">Numéros GL</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/projects">Projets</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/divisions">Divisions</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/companies">Compagnies</NavLink>
                  </NavItem></>
                }
                  <NavItem>
                    <NavLink tag={Link} to="/logout">Logout</NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sessionData: state.session,
  isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
})

const connectedNavigation = connect(mapStateToProps)(Navigation);
export { connectedNavigation as Navigation };
