import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { companyActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-free-solid'

class DeleteCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.deleteCompany = this.deleteCompany.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  deleteCompany() {
    this.props.delete(this.props.company_id);
    this.toggle();
  }

  render() {
    return (
      <div>
        <Button color="danger" className="full-width" onClick={this.toggle}><FontAwesomeIcon icon={faTimes} color="white" size="lg" /></Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>Supprimer une compagnie</ModalHeader>
          <ModalBody>
            <div>Voulez-vous supprimer une compagnie?</div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button color="danger" onClick={this.deleteDivision}>Supprimer</Button>
              </Col>
              <Col xs="6" className="text-center" >
                <Button color="secondary" onClick={this.toggle}>Annuler</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    delete: (company_id) => {
      dispatch(companyActions.delete(company_id))
    }
  }
}

const connectedDeleteCompanyModal = connect(mapStateToProps, mapDispatchToProps)(DeleteCompanyModal);
export { connectedDeleteCompanyModal as DeleteCompanyModal };
