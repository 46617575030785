import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import { error as toastError } from 'react-toastify-redux';
import { store, history } from '../_helpers';

export const reservationService = {
    getAll,
    update,
    create,
    charter,
    delete: _delete
};

function getAll(page, sizePerPage, filters) {

    if (page === undefined)
    {
        page = 1;
    }

    if (sizePerPage === undefined)
    {
        sizePerPage = 10;
    }

    if (filters === undefined || filters === "")
    {
        filters = {};
    }


    const config = {
      headers: { ...authHeader() }
    };

    const searchQueryAndFilters = {
      page: page,
      sizePerPage: sizePerPage,
      filters: filters
    }

    return axios.post('/search_reservations', {...searchQueryAndFilters, query_type:1}, config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load reservations. Please try again later.")); throw "failure";});
}

function get(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/reservation/' + id, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}

function update(edit) {
    const config = {
      headers: { ...authHeader() }
    };

    return axios.post('/reservation', edit, config).then(handleResponse).catch(error => {console.log(error.response)});
}

function create(values) {
    const config = {
      headers: { ...authHeader() }
    };
    console.log(values);
    if (values['completed'] == false) {
        return axios.put('/reservation_request', values, config).then(handleResponse).catch(error => {console.log(error.response)});
    } else {
        return axios.put('/reservation', values, config).then(handleResponse).catch(error => {console.log(error.response)});
    }
    
}

function charter(id) {
    const headers = {
        headers: { ...authHeader() }
    };
    const values = {
        id: id
    };

    return axios.put('/reservation_charter', values, headers).then(handleResponse).catch(error => {console.log(error.response)});
}


// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, reason) {
    const requestOptions = {
        headers: authHeader(),
        data: {id: id, reason: reason}
    };

    return axios.delete('/reservation', requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}
