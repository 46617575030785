import { companyConstants } from '../_constants';
import { companyService } from '../_services';
import { error as toastError} from 'react-toastify-redux';
import { history } from '../_helpers';

export const companyActions = {
    getAll,
    createNew,
    update,
    delete: _delete
  };


function getAll() {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        companyService.getAll()
            .then(
                companies => {
                    console.log("THEN SUCCESS");
                    dispatch(success(companies))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: companyConstants.GETALL_REQUEST } }
    function success(companies) { return { type: companyConstants.GETALL_SUCCESS, companies } }
    function failure(error) { return { type: companyConstants.GETALL_FAILURE, error } }
}

function createNew(company)  {
    return dispatch => {
        dispatch(request(company));

        companyService.create(company)
            .then(
                company => {
                    dispatch(success(company));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: companyConstants.CREATE_REQUEST, company } }
    function success(id) { return { type: companyConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: companyConstants.CREATE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return companyService.update(edit)
            .then(
                company => {
                    dispatch(success(company));
                    return company;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: companyConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: companyConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: companyConstants.UPDATE_FAILURE, id, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        companyService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: companyConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: companyConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: companyConstants.DELETE_FAILURE, id, error } }
}
