import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const LoginRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        <Component {...props} />
    )} />
)

export { LoginRoute };
