import React from 'react';
import { connect } from 'react-redux';
import { companyActions } from '../_actions';
import { NewCompanyModal, DeleteCompanyModal } from "../Companies"
import { Form, Row, Col } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

function deleteLinkFormatter(value, row, index, field) {
  return (
    <DeleteCompanyModal className="full-width" color="danger" project_id={row.value}></DeleteCompanyModal>
  );
}

function selectDataFormatterLabel(cell, row) {
  return <span>{row.label}</span>
}

const columns = [{
  dataField: 'label',
  text: 'Compagnie',
  editable: true
},{
  dataField: 'delete',
  text: 'Supprimer',
  editable: false,
  formatter: deleteLinkFormatter
}];

const remote={
  filter: false,
  pagination: false,
  sort: false,
  cellEdit: true
}

class AllCompanies extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        companies: []
      };
    }

  componentDidMount() {
    this.props.getAllCompanies();
  }

  onTableChange(type, newState) {
    if(type==="cellEdit")
    {
      this.props.handleChange(newState.cellEdit);
    }
    return true;
  }

  render() {
    return (
      <div style={{marginLeft:"5%", marginRight:"5%"}}>
        <Row>
          <Col md="3" xs="6" style={{marginBottom: "10px"}}><NewCompanyModal buttonLabel="Nouvelle compagnie" /></Col><Col md="1" xs="6"></Col>
        </Row>
        <br/>
        { this.props.companies && this.props.companies.items && this.props.companies.items.length > 0 &&
          <div className="table-responsive-sm">
            <BootstrapTable style={{ margin:"auto"}} bootstrap4={true} bordered={false} keyField='value' remote={remote} cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) } onTableChange={this.onTableChange.bind(this)} data={ this.props.companies.items } columns={ columns } />
          </div>
        }
      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (edit) => {
      dispatch(companyActions.update(edit));
    },
    getAllCompanies: () => {
      dispatch(companyActions.getAll());
    }
  }
}

function mapStateToProps(state){
    return {
        companies: state.companies
    }
}

const connectedAllCompanies = connect(mapStateToProps, mapDispatchToProps)(AllCompanies);
export { connectedAllCompanies as AllCompanies };
