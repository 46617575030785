import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input } from 'reactstrap';
import { reservationActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-free-solid'

class CharteredReservationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.charterReservation = this.charterReservation.bind(this);

  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  charterReservation() {
    console.log("charter");
    console.log(this.props.reservation_id);
    this.props.charter(this.props.reservation_id);
    this.toggle();
  }

  render() {
    return (
      <div>
        <Button className="full-width" onClick={this.toggle}>Nolisé</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>Nolisé</ModalHeader>
          <ModalBody>
            <Label>Voulez vous envoyer un courriel à dispatch@panoramaaviation.com
            </Label>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button color="danger" onClick={this.charterReservation}>Envoyer le courriel</Button>
              </Col>
              <Col xs="6" className="text-center" >
                <Button color="secondary" onClick={this.toggle}>Retour</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    charter: (reservation_id) => {
      dispatch(reservationActions.charter(reservation_id))
    }
  }
}

const connectedCharteredReservationModal = connect(mapStateToProps, mapDispatchToProps)(CharteredReservationModal);
export { connectedCharteredReservationModal as CharteredReservationModal };
