import { userConstants, favoriteMedicationsConstants } from '../_constants';

export function session(state = {}, action) {
  switch (action.type) {
    case userConstants.REFRESHING_TOKEN:
      return {
        ...state,
        freshTokenPromise: action.freshTokenPromise,
        refreshing: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        loggedIn: true,
        refreshing: false,
        freshTokenPromise: null
      };
    case userConstants.DONE_REFRESHING_TOKEN:
      return {
        ...state,
        user: {...state.user, authentication_token: action.freshToken},
        loggedIn: true,
        refreshing: false,
        freshTokenPromise: null
      };
    case userConstants.LOGOUT:
      return { };
    default:
      return state
  }
}
