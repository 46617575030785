import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Navigation } from '../_components';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        rest.isAuthenticated
            ? <div><Navigation></Navigation><br/><Component {...props} /></div>
            : <div><Redirect exact to={{ pathname: '/login', state: { from: props.location } }} /></div>
    )} />
)

const mapStateToProps = state => ({
  sessionData: state.session,
  isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
})

const connectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);
export { connectedPrivateRoute as PrivateRoute };
