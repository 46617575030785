import React from 'react';
import DatePicker from "react-datepicker";
import parseISO from 'date-fns/parseISO'

const FormikDatePicker: React.SFC<ReactSelectProps & FieldProps> = ({
    options,
    field,
    form,
    label,
    error,
    withHr,
    touched,
    handleBlur
  }) => (
      <DatePicker
      selected={typeof field.value === 'string' ? parseISO(field.value) : field.value}
      onSelect={(value) => form.setFieldValue(field.name, (new Date(value.getTime() - (value.getTimezoneOffset() * 60000))))}
      onChange={(value) => form.setFieldValue(field.name, (new Date(value.getTime() - (value.getTimezoneOffset() * 60000))))}
      value={field.value}
      onBlur={field.onBlur}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
        },
      }}
      withPortal
      />
    )

export { FormikDatePicker };
