import React from 'react'
import ReactSelect, { Option, ReactSelectProps } from 'react-select'
import { FieldProps, Field } from 'formik'
import { FormGroup, Label, Input } from 'reactstrap'

const singleValueStyle = {
  singleValue: (styles, { data }) => ({ ...styles, overflow: 'visible' }),
};

const FormikSelectWithOther: React.SFC<ReactSelectProps & FieldProps> = ({
    options,
    field,
    form,
    label,
    error,
    withHr,
    touched,
    handleBlur
  }) => (
      <div className="form-group">
        <div>
          { label &&
            <label htmlFor="color">{label}</label>
          }
          <ReactSelect
            options={[...options, {label:"Autre", value:-1}]}
            name={field.name}
            value={options ? options.find(option => option.value == field.value) : {label:"failed", value:"failed"}}
            onBlur={field.onBlur}
            styles={singleValueStyle}
            onChange={(option: Option) => {
                                            form.setFieldValue(field.name, option.value)
                                          }}
          />
          {field.value === -1 && (
              <FormGroup>
                <Label for="label">Spécifier</Label>
                <Input tag={Field} type="text" name={field.name+"_new_value"} id={field.name+"_new_value"} />
              </FormGroup>
            )}
          {!!error &&
            touched && (
              <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>
            )}
        </div>
        {withHr && <hr/> }
      </div>
    )

export { FormikSelectWithOther };
