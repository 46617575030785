import { divisionConstants } from '../_constants';

export function divisions(state = {items: []}, action) {
  switch (action.type) {
    case divisionConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case divisionConstants.GETALL_SUCCESS:
      return {
        items: action.divisions.data
      };
    case divisionConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case divisionConstants.DELETE_REQUEST:
      // add 'deleting:true' property to division being deleted
      return {
        ...state,
        items: state.items.map(division =>
          division.id === action.id
            ? { ...division, deleting: true }
            : division
        )
      };
    case divisionConstants.DELETE_SUCCESS:
      // remove deleted division from state
      return {
        items: state.items.filter(division => division.id !== action.id)
      };
    case divisionConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to division
      return {
        ...state,
        items: state.items.map(division => {
          if (division.id === action.id) {
            // make copy of division without 'deleting:true' property
            const { deleting, ...divisionCopy } = division;
            // return copy of division with 'deleteError:[error]' property
            return { ...divisionCopy, deleteError: action.error };
          }

          return division;
        })
      };
    case divisionConstants.UPDATE_REQUEST:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (division, index) => {
            if(division.value === action.edit.rowId){
              return { ...division, [action.edit.dataField]:action.edit.newValue }
            }
            return division
          })
      };
    default:
      return state
  }
}
