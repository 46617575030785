import React from 'react';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/fontawesome-free-solid';
import { reservationActions } from '../_actions';
import { connect } from 'react-redux';

class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { inputValue: '' };
  }

  handleInputChange = (newValue: string) => {
      const inputValue = newValue.replace(/\W/g, '');

      this.props.dispatch(reservationActions.updateSearch(inputValue));

      return inputValue;
   };

  render() {
    return (
		<InputGroup>
		    <Input placeholder="Search..." onChange={(e) => this.handleInputChange(`${e.target.value}`)} defaultValue={this.props.searchQuery}/>
		  </InputGroup>
		)
	}
}


function mapStateToProps(state) {
    return {};
}

const connectedSearchBar = connect(mapStateToProps)(SearchBar);
export { connectedSearchBar as SearchBar };
