export const airportConstants = {

    GETALL_REQUEST: 'AIRPORTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'AIRPORTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'AIRPORTS_GETALL_FAILURE',

    DELETE_REQUEST: 'AIRPORTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'AIRPORTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'AIRPORTS_DELETE_FAILURE',

    CREATE_REQUEST: 'AIRPORT_CREATE_REQUEST',
    CREATE_SUCCESS: 'AIRPORT_CREATE_SUCCESS',
    CREATE_FAILURE: 'AIRPORT_CREATE_FAILURE',
    UPDATE_REQUEST: 'AIRPORT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'AIRPORT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'AIRPORT_UPDATE_FAILURE',

};
