import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import { jwt } from './jwt'
import { userService } from '../_services';
import { userActions } from '../_actions';

const loggerMiddleware = createLogger();

const middleware = applyMiddleware(
    jwt,
    thunkMiddleware,
    loggerMiddleware
)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(middleware)
);
