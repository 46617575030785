import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, FormFeedback } from 'reactstrap';
import { companyActions } from '../_actions';
import BootstrapTable from 'react-bootstrap-table-next';
import { history } from '../_helpers';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FormikSelect as Select } from '../_components';
import ReactSelect from 'react-select'

const columns = [{
  dataField: 'label',
  text: 'Compagnie'
}];

class NewCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.createNewCompany = this.createNewCompany.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  createNewCompany(values, { props, setSubmitting }) {
      this.props.dispatch(companyActions.createNew(values))
      setSubmitting(false);
      this.toggle();
      // resetForm();
  }

  render() {
    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>Ajouter une compagnie</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={this.createNewCompany}
            initialValues={{
              label: ""
          	}}
            validationSchema={Yup.object().shape({
              label: Yup.string()
                .required('Nom de compagnie requis.')
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Label for="label">Compagnie</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.label && touched.label} tag={Field} type="text" value={values.label} name="label" id="label" />
                  {errors.label && touched.label && <div className="input-feedback" style={{color:"red"}}>{errors.label}</div>}
                </FormGroup>
                <Row>
                  <Col xs="6" className="text-center">
                    <Button type="submit" color="primary" onClick={(e) => {handleSubmit(e);}} disabled={isSubmitting}>Ajouter</Button>
                  </Col>
                  <Col xs="6" className="text-center" >
                    <Button color="secondary" onClick={this.toggle}>Annuler</Button>
                  </Col>
                </Row>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { companies } = state;
  return {
      divisions: companies
  };
}

const connectedNewCompanyModal = connect(mapStateToProps)(NewCompanyModal);
export { connectedNewCompanyModal as NewCompanyModal };
