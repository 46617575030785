import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { NewUserModal, DeleteUserModal } from "../Users"
import { Form, Row, Col, Input } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { store } from '../_helpers';

function deleteLinkFormatter(value, row, index, field) {
  return (
    <DeleteUserModal className="full-width" color="danger" id={row.value}></DeleteUserModal>
  );
}

function onActiveChange(value, row) {

  if (value == 1 || value == true) {
    value = false;
  }
  else {
    value = true;
  }
  var cellEdit = { dataField: "active", newValue: value.toString(), rowId: row.id}

  store.dispatch(userActions.update(cellEdit))

}

function onAdminChange(value, row) {

  if (value == 1 || value == true) {
    value = false;
  }
  else {
    value = true;
  }
  var cellEdit = { dataField: "is_admin", newValue: value.toString(), rowId: row.id}

  store.dispatch(userActions.update(cellEdit))

}

function onReadOnlyChange(value, row) {

  if (value == 1 || value == true) {
    value = false;
  }
  else {
    value = true;
  }
  var cellEdit = { dataField: "is_readonly", newValue: value.toString(), rowId: row.id}

  store.dispatch(userActions.update(cellEdit))

}

function checkboxAdminFormatter(cell, row) {

  const admin = row.is_admin==true||row.is_admin==1||row.is_admin=='true'?true:false;
  return (
    <div>
      <Input type="checkbox" defaultChecked={ admin } onChange={ () => onAdminChange(row.is_admin, row)  } style={{margin: "auto", position: "relative"}} />
    </div>
  );
}

function checkboxReadOnlyFormatter(cell, row) {

  const is_readonly = row.is_readonly==true||row.is_readonly==1||row.is_readonly=='true'?true:false;
  return (
    <div>
      <Input type="checkbox" defaultChecked={ is_readonly } onChange={ () => onReadOnlyChange(row.is_readonly, row)  } style={{margin: "auto", position: "relative"}} />
    </div>
  );
}

function checkboxFormatter(cell, row) {

  const active = row.active==true||row.active==1||row.active=='true'?true:false;

  return (
    <div>
      <Input type="checkbox" defaultChecked={ active } onChange={ () => onActiveChange(row.active, row)  } style={{margin: "auto", position: "relative"}} />
    </div>
  );
}

const columns = [{
  dataField: 'first_name',
  text: 'Prénom'
}, {
  dataField: 'last_name',
  text: 'Nom de famille'
}, {
  dataField: 'email',
  text: 'Courriel'
}, {
  dataField: 'email_2',
  text: 'Second Courriel'
}, {
  dataField: 'active',
  text: 'Actif',
  editable: false,
  formatter: checkboxFormatter,
},{
  dataField: 'is_admin',
  text: 'Administrateur',
  editable: false,
  formatter: checkboxAdminFormatter,
},{
  dataField: 'is_readonly',
  text: 'Lecture seulement',
  editable: false,
  formatter: checkboxReadOnlyFormatter,
}, {
  dataField: 'date_of_birth',
  text: "Date de naissance",
  formatter: (cell, row) => {
    let dateObj = row.date_of_birth;
    if ( dateObj == null )
    {
      return null;
    }
    else {
      dateObj = new Date(row.date_of_birth);
    }
    return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
  },
  editor: {
    type: Type.DATE
  }
}, {
  dataField: 'password',
  text: 'Mot de passe'
}, {
  dataField: 'delete',
  text: 'Supprimer',
  editable: false,
  formatter: deleteLinkFormatter
}];

const remote={
  filter: false,
  pagination: false,
  sort: false,
  cellEdit: true
}

class AllUsers extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        users: []
      };
    }

  componentDidMount() {
    this.props.getAllUsers();
  }

  onTableChange(type, newState) {
    console.log("ON CELL EDIT");
    console.log(type);
    if(type==="cellEdit")
    {
      this.props.handleChange(newState.cellEdit);
    }
    return true;
  }

  render() {
    var users = this.props.users.items;

    if(users && users.length) {
      users.sort(function(a, b){
       var nameA="";
       var nameB="";
       
       if (a.first_name) {
              nameA=a.first_name.toLowerCase();
       }
       
       if (b.first_name) {
              nameB=b.first_name.toLowerCase();
       }

       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0; //default return value (no sorting)
      });
    }

    return (
      <div style={{marginLeft:"5%", marginRight:"5%"}}>
        <Row>
          <Col md="3" xs="6" style={{marginBottom: "10px"}}><NewUserModal buttonLabel="Nouveau Voyageur" /></Col><Col md="1" xs="6"></Col><Col md="8" xs="12"></Col>
        </Row>
        <br/>
        {users && users.length > 0 &&
          <BootstrapTable bootstrap4={true} bordered={false} keyField='id' remote={remote} cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) } onTableChange={this.onTableChange.bind(this)} data={ this.props.users.items } columns={ columns } />
        }
      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (edit) => {
      dispatch(userActions.update(edit));
    },
    getAllUsers: () => {
      dispatch(userActions.getAll());
    }
  }
}

function mapStateToProps(state){
    return {
        users: state.users
    }
}

const connectedAllUsers = connect(mapStateToProps, mapDispatchToProps)(AllUsers);
export { connectedAllUsers as AllUsers };
