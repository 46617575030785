import { companyConstants } from '../_constants';

export function companies(state = {items: []}, action) {
  switch (action.type) {
    case companyConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case companyConstants.GETALL_SUCCESS:
      return {
        items: action.companies.data
      };
    case companyConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case companyConstants.DELETE_REQUEST:
      // add 'deleting:true' property to division being deleted
      return {
        ...state,
        items: state.items.map(company =>
          company.id === action.id
            ? { ...company, deleting: true }
            : company
        )
      };
    case companyConstants.DELETE_SUCCESS:
      // remove deleted division from state
      return {
        items: state.items.filter(company => company.id !== action.id)
      };
    case companyConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to division
      return {
        ...state,
        items: state.items.map(company => {
          if (company.id === action.id) {
            // make copy of division without 'deleting:true' property
            const { deleting, ...companyCopy } = company;
            // return copy of division with 'deleteError:[error]' property
            return { ...companyCopy, deleteError: action.error };
          }

          return company;
        })
      };
    case companyConstants.UPDATE_REQUEST:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (company, index) => {
            if(company.value === action.edit.rowId){
              return { ...company, [action.edit.dataField]:action.edit.newValue }
            }
            return company
          })
      };
    default:
      return state
  }
}
