import React, {ReactDOM} from 'react';
import { TreeView } from '@progress/kendo-react-treeview';

class DestinationTreeView extends React.Component {
    render() {
        return (
            <TreeView
                data={this.props.data}
                expandIcons={true}
                onExpandChange={this.onExpandChange}
                aria-multiselectable={true}
            />
        );
    }
    onExpandChange = (event) => {
        event.item.expanded = !event.item.expanded;
        this.forceUpdate();
    }
}

export { DestinationTreeView }
