import { GLNumberConstants } from '../_constants';
import { GLNumberService } from '../_services';
import { error as toastError} from 'react-toastify-redux';
import { history } from '../_helpers';

export const GLNumberActions = {
    getAll,
    createNew,
    update,
    delete: _delete
  };


function getAll() {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        GLNumberService.getAll(1, 10, "")
            .then(
                gl_numbers => {
                    console.log("THEN SUCCESS");
                    dispatch(success(gl_numbers))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: GLNumberConstants.GETALL_REQUEST } }
    function success(gl_numbers) { return { type: GLNumberConstants.GETALL_SUCCESS, gl_numbers } }
    function failure(error) { return { type: GLNumberConstants.GETALL_FAILURE, error } }
}

function createNew(gl_number)  {
    return dispatch => {
        dispatch(request(gl_number));

        GLNumberService.create(gl_number)
            .then(
                gl_number => {
                    dispatch(success(gl_number));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: GLNumberConstants.CREATE_REQUEST, gl_number } }
    function success(id) { return { type: GLNumberConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: GLNumberConstants.CREATE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return GLNumberService.update(edit)
            .then(
                gl_number => {
                    dispatch(success(gl_number));
                    return gl_number;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: GLNumberConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: GLNumberConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: GLNumberConstants.UPDATE_FAILURE, id, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        GLNumberService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: GLNumberConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: GLNumberConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: GLNumberConstants.DELETE_FAILURE, id, error } }
}
