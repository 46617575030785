import { airportConstants } from '../_constants';

export function airports(state = {items: []}, action) {
  switch (action.type) {
    case airportConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case airportConstants.GETALL_SUCCESS:
      return {
        items: action.airports.data
      };
    case airportConstants.CREATE_SUCCESS:

      return {
        ...state,
        items: [
            ...state.items,
            action.id.data
        ]
      };
    case airportConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        items: state.items.filter(airport => airport.value !== action.id)
      };
    case airportConstants.UPDATE_REQUEST:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (airport, index) => {
            if(airport.value.value === action.edit.rowId){
              return { ...airport, [action.edit.dataField]:action.edit.newValue }
            }
            return airport
          })
      };
    default:
      return state
  }
}
