import { GLNumberConstants } from '../_constants';

export function gl_numbers(state = {items: []}, action) {
  switch (action.type) {
    case GLNumberConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case GLNumberConstants.GETALL_SUCCESS:
      return {
        items: action.gl_numbers.data
      };
    case GLNumberConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case GLNumberConstants.DELETE_REQUEST:
      // add 'deleting:true' property to gl_number being deleted
      return {
        ...state,
        items: state.items.map(gl_number =>
          gl_number.id === action.id
            ? { ...gl_number, deleting: true }
            : gl_number
        )
      };
    case GLNumberConstants.DELETE_SUCCESS:
      // remove deleted gl_number from state
      return {
        items: state.items.filter(gl_number => gl_number.id !== action.id)
      };
    case GLNumberConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to gl_number
      return {
        ...state,
        items: state.items.map(gl_number => {
          if (gl_number.id === action.id) {
            // make copy of gl_number without 'deleting:true' property
            const { deleting, ...gl_numberCopy } = gl_number;
            // return copy of gl_number with 'deleteError:[error]' property
            return { ...gl_numberCopy, deleteError: action.error };
          }

          return gl_number;
        })
      };
    case GLNumberConstants.UPDATE_REQUEST:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (gl_number, index) => {
            if(gl_number.value === action.edit.rowId){
              return { ...gl_number, [action.edit.dataField]:action.edit.newValue }
            }
            return gl_number
          })
      };
    default:
      return state
  }
}
